html {
    // overflow: hidden;
    // height: 100%;
}

body {
    // overflow: auto;
    // height: 100%;
    font-family: 'Nunito Sans', sans-serif;  
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.relative {
  position: relative;
}

#page {
  overflow:hidden;
}

$primary-color: #000;
$secondary-color: #E0C6B3;
$dark-color: #4F4C48;
$light-brown: #E1D7C8;

a:hover {
  color: #171F30;
}

h1, h2, h3, h4 {
  font-family: 'Nunito Sans', sans-serif;  
}

h1 {
  font-size: 20px;  
  line-height: 1.2;
  @include breakpoint(large) {
    font-size: 35px;
  }
}

h2 {
  font-size: 15px;
  color: rgba(0,0,0,0.85);  

  @include breakpoint(large) {    
    font-size: 25px;
  }
}

h3 {
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 9px;
  display: inline-block;
  @include breakpoint(large) {
    font-size: 18px; 
  }
}

h4 {
  font-size: 16px;
  @include breakpoint(large) {
    font-size: 25px; 
  }
}

p {  
  font-family: 'Nunito Sans', sans-serif;
  font-weight:400;
  font-size:14px;
  letter-spacing: 0;  
  line-height:1.5;

  @include breakpoint(large) {
    font-size:18px;
  }

  &:last-child {
    margin-bottom:0;
  }
}

p.large {
  font-size: 25px;
  line-height: 1.4;
}



.small {
  font-size: 11px;
}

.xsmall {
  font-size: 9px;
}

a {
  font-size: 16px;
  color: $primary-color;
  @include breakpoint(large) {
    font-size: 20px;
  }
}

.thin {
  font-weight:300!important;
}

.bold {
  font-weight: 800;
}

.bg-primary {  
  background: $primary-color;
}

.bg-secondary {
  background: $secondary-color;
}


.bg-light-grey {
  background: #D8D8D8;
}

.bg-light-blue {
  background: #8396a0;
}

.bg-light-brown {
  background: $light-brown;
}


.bg-white {
  background: #fff;
}

.bg-black {
  background: #000;
}

.primary {
  color: $primary-color!important;
}

.secondary {
  color: $secondary-color!important;
}

.white {
  color: white!important;
}

.pink {
  color: #E0C6B3;
}

.grey {
  color: #98989A!important;
}

.shadow {
  box-shadow: 0px 0px 12px -1px rgba(0,0,0,0.88);
}

.mobile-order-1 {
  order: 1;
  @include breakpoint(large) {
    order: initial;
  }
}

.mobile-order-2 {
  order: 2;
  @include breakpoint(large) {
    order: initial;
  }
}


.mobile-hide {
  display:none;
  @include breakpoint(large) {
    display:block;
  }
}


.spacing20 {
  height: 20px;
}

.spacing40 {
  height: 20px;
  @include breakpoint(large) {
    height: 40px;
  }
}

.spacing60 {
  height: 30px;
  @include breakpoint(large) {
    height: 60px;
  }
}

.spacing80 {
  height: 40px;
  @include breakpoint(large) {
    height: 80px;
  }
}

.spacing100 {
  height: 20px;
  @include breakpoint(large) {
    height: 100px;
  }
}


input, select, textarea {
  background-color: transparent!important;
  margin:0 0 15px;
  border: 0!important;
  box-shadow: none;
  outline: 0;
  border-style:solid;
  border-width:0;
  border-bottom: 1px solid white!important;
  font-size: 14px;  
  color:white!important;
  padding-left: 0!important;
}

textarea {
  min-height: auto;
}


.button {
  margin-top: 40px;
  background-color:black;
  // border: 1px solid blac;
  &:hover {
    background-color:white;
    color: black!important;
  }  
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white!important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: white!important;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: white!important;
}


header {
  background-color: rgba(0,0,0,0.95);
  position:fixed;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 4;
}

.bg {
  background: url('/assets/images/bg.jpg') top center no-repeat;
  background-size: cover;
  padding: 10px;
  @include breakpoint(large) {
    padding: 120px 150px;
  }
}


.wrap {  
  background-color: rgba(103,185,196,0.9);
  padding: 20px 0;

  @include breakpoint(large) {
    padding: 50px 0;    
  }
}
.center {
  text-align: center;
}

.logo {
  width: 100%;  
  max-width: 209px;
  padding: 20px 30px;    
  z-index: 1;
  transition: 1.0s all ease;
  display: inline-block;
  
  @include breakpoint(large) {
    max-width: 240px;
    margin: 0 auto;
  }
}


.inner-wrap {
  width: 90%;
  margin: 0 auto;

  @include breakpoint(large) {
    width: 80%;
  }
}

.form {
  background-color:rgba(0,0,0,0.85);
  padding: 10px;
  label {
    color: white;
    text-align:left;
  }

  @include breakpoint(large) {
    padding: 40px;
  }

  &.thank {
    padding: 10px;

    @include breakpoint(large) {
      padding: 60px 40px;
    }
  }

  .small {
    text-align:left;
    font-size: 10px;
    width: 95%;
    margin: 20px auto 0;
  }
}

.footer {
  
  .dimex {
    width: 80%;
    max-width: 230px;
  }
}

select option { color: black; }


$tcon-size: 30px !default;
$tcon-transition: .3s !default;
$tcon-jstransform: 'tcon-transform' !default;

$tcon-menu-radius: ($tcon-size / 14) !default;
$primary-color: #a20060 !default;
$tcon-menu-arrowleft-transformation: (
  transform: scale3d(.8, .8, .8) rotate3d(0, 0, 1, 90deg)
) !default;
$tcon-menu-arrow360left-transformation: (
  transform: scale3d(.8, .8, .8) rotate3d(0, 0, 1, 360deg)
) !default;
.tcon {
  transform: rotate(-90deg);
  position: fixed;
  top: 10px;
  right: 10px;
  float:right;
  z-index: 99999;
  appearance: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: $tcon-size;
  transition: $tcon-transition;
  user-select: none;
  width: $tcon-size;
  background: transparent;
  outline: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
  
    // border-radius: 50px;
    padding: 23px 8px;

  @include breakpoint(medium) {
    top: 14px;
    right: 10px;
  }

  > * {
    display: block;
  }

  &:hover,
  &:focus {
    outline: none; // see issue #36 https://github.com/grayghostvisuals/transformicons/issues/36
  }
  &::-moz-focus-inner {
    border: 0;
  }
}

@mixin tcon-menu-lines {
  display: inline-block;
  height: ($tcon-size / 10);
  width: $tcon-size;
  border-radius: $tcon-menu-radius;
  transition: $tcon-transition;
  background: #fff;
}

.tcon-menu__lines {
  @include tcon-menu-lines;
  position: relative;

  &::before,
  &::after {
    @include tcon-menu-lines;
    content: '';
    position: absolute;
    left: 0;
    transform-origin: ($tcon-size / 14) center;
    width: 100%;
  }

  &::before {
    top: ($tcon-size / 3);
  }

  &::after {
    top: -($tcon-size / 3);
  }

  .#{$tcon-jstransform} & {
    transform: scale3d(.8, .8, .8);
  }
}
.tcon-menu--xcross {
  width: auto;

  &.#{$tcon-jstransform} {
    .tcon-menu__lines {
      background: transparent;

      &::before,
      &::after {
        transform-origin: 50% 50%;
        top: 0;
        width: $tcon-size;
      }

      &::before {
        transform: rotate3d(0, 0, 1, 45deg);
      }

      &::after {
        transform: rotate3d(0, 0, 1, -45deg);
      }
    }
  }
}
// see issue #7 https://github.com/grayghostvisuals/transformicons/issues/7#issuecomment-59813596
.tcon-visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;

  &:active,
  &:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
  }
}

.hero {
  width: 100%;
  height: 55vh;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position:relative;
  opacity: 0;
  transition: 0.4s all ease;
  &.active {
    opacity: 1;
  }

  @include breakpoint(large) {
    height: 100vh;
  }
}

.heading {
  padding: 10px 0 30px;
  background: rgba(0,0,0,0.7);
  position:absolute;
  left: 0;
  bottom: 0;  
  width: 100%;
  text-align: center;
  h1 {    
    transform: translateY(10px);
    font-size: 16px;
    line-height: 1;
    margin-bottom: 0;
    letter-spacing: 4px;
    @include breakpoint(large) {
      font-size: 24px;

    }
  }

  @include breakpoint(large) {
    padding: 20px 0 40px;
    width: 100%;
    text-align: center;
  }
}

.video-slide h1 {
  transition: 0.7s all cubic-bezier(0.505, 0.290, 0.815, 0.755);
  transition-delay: 1.2s;
  opacity: 0;
}

.video-slide .slick-active h1 {
 opacity: 1;
 transform: translateY(0); 
}


.home {
   .hero {  
    background-image: url('/assets/images/mobile-home.jpg');

    @include breakpoint(large) {
      background-image: none;
    }
   }
}

.communities .hero {
  // background-color:black;
  height: auto;

  .heading {
    bottom: auto;
    top: 100px;
    width: 100%;
    position:static;
    padding-top: 95px;
    padding-bottom: 40px;
    background: none;
    text-align: left;
    margin-left: 10px;

    @include breakpoint(large) {
      width: 70%;
      margin-left: 145px;
    }
    h1 { 
      color: black;
      opacity: 1;
    }
  }
}

.about .hero {
  background-image: url('/assets/images/about/hero.jpg');
}

.sustainability .hero {
  background-image: url('/assets/images/sustainability/hero.jpg');
}

.homeowner .hero {
  background-image: url('/assets/images/homeowner/hero.jpg');
}

.contact .hero {
  background-image: url('/assets/images/contact/hero.jpg');
}

.v-align-center {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @include breakpoint(large) {
    flex-wrap: nowrap;
  }
}

.center-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.padding-side {
  padding: 3%!important;
  @include breakpoint(large) {
    padding: 0 10%!important;
  }
}

.padding-side-half {
  padding: 3%!important;
  @include breakpoint(large) {
    padding: 0 5%!important;
  }
}

.padding-around {
  padding: 3%;
  @include breakpoint(large) {
    padding: 15%;
  }
}

nav {
  position: fixed;
  width: 280px;
  right: -330px;  
  top: 0;
  background: black;
  padding: 80px 60px 0;
  transition: 0.5s all ease;
  z-index: 23;
  padding-bottom: 50px;

  @include breakpoint(large) {
    background: rgba(0,0,0,0.95);
    width: 330px;
  }

  &.active {
    right: 0;    
    .slide-nav {
      display: none;
    }
  }

  .links {
    margin-right: 18px;
    a {
      text-align: right;
      display: block;
      margin-bottom: 20px;
      color: white;
    }
  }

  
}

.social {
    margin: 80px 0 15px;
    text-align: center;
    a {
      margin: 0 8px;
      width: 25px;
      height: 25px;
      display: inline-block;

      &.facebook {
        width:15px;
      }

      svg {
        width:100%;
      }
    }
  }

footer {
  padding: 20px 0 40px;
  background-color:black;
  // z-index: 30;
  // position:relative;
  .social {
    margin: 0;
    text-align: left;
    display: none;
    @include breakpoint(large) {
      display: block;
    }

    a {
      margin-right: 15px;
      margin-left: 0;
    }
    .Footer {
      fill:white;
    }
  }
  .footer-logo {
    width: 80%;    
  }

  .main {
    h2 {
      font-weight: 800;
      font-size: 14px;    
      letter-spacing: 9px;
      margin-bottom: 20px;
    }

    p, a {    
      font-size: 15px;    
      letter-spacing: 0;  
      color:white;
      line-height: 1.4;
      font-weight: 300;
      display: block;
      
    }
    .footer-contact a {
      display: inline-block;
    }
    p {
      font-size: 14px;
    }  
  }

  
  a {
    margin-bottom:5px;
  }

  a:hover {
    color: white;
  }

  .copyright {
    p, a {
      color: white;
      font-size: 13px;  
    }    
  }
}

.video_wrapper, .video-slide .slide4 {
 margin:0px;
 padding:0px;
 // display: none;
 opacity: 0;
 transition: 1s opacity cubic-bezier(1.000, 0.005, 0.000, 1.000);
 transition-delay: 1s;
 @include breakpoint(large) {
  display: block;  
 }
}

.video-slide .slide4 {
  height: 100%;
}

.slick-active .video_wrapper, .video-slide .slick-active .slide4 {
  opacity: 1;
}

.video_wrapper video
{
 margin-top: 70px;
 width: 100%;
 height: auto; 

 @include breakpoint(large) {
  margin-top: 0;
  position: absolute;
  top: 50%; left: 50%;
  z-index: -1;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translate(-50%, -50%);   
 }

}

.vertical-text{  
  position:absolute;
  left: 0;
  top: 0;
  writing-mode: tb-rl;
  
  align-items: flex-end;
  justify-content: flex-start;
  display: none;
  @include breakpoint(large) {
    display: flex
  }

  &-content {
    transform: rotate(180deg);
    text-align: left; 
    font-weight: 800;
    font-size: 11px;    
    letter-spacing: 5px;  
    padding-bottom: 40px;  
    flex-shrink: 0;


    &:after {
      content: '';
      border-left: 3px solid black;
      position:absolute;
      right: 0;
      bottom: 0;
      width: 8px;
      height: 30px;
    }

  }
}


.arrows {
  position: absolute;  
  z-index: 15;
  bottom: 0;
  left: 0;
  width: 100%;
  button {    
    text-indent: -9999px;
    
    overflow:hidden;
    width: 40px;
    height: 40px;    
    background: url('/assets/images/arrow-right.svg') #000 center center no-repeat;
    background-size: 12px 20px;
    display: inline-block;

    transform: rotate(180deg);
    transform-origin: center center;    
    float:left;

    &.slick-next {
      transform: rotate(0);
      transform-origin: center center;
      float:right;
    }
  }

}

.project-slides {
  .slide {
    min-height: 30vh;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;    
    width: 100%;
    @include breakpoint(large) {
      min-height: 90vh;
    }
  }

  .slide1 {
    background-image: url('/assets/images/Lakewood-desk.jpg');
  }
  .slide2 {
    background-image: url('/assets/images/Templeton.jpg');
  }
  .slide3 {
    background-image: url('/assets/images/nicola.jpg');
  }
  .slide4 {
    background-image: url('/assets/images/eternity.jpg');
  }
}

.home-slide {
  background-color:white;
  position:relative;
  
}

.project-info {  
  background: #D8D8D8;
  width: 100%;
  overflow: hidden;
  padding: 70px 20px 20px;
  position:relative;
  left:0;
  top:0;

  @include breakpoint(large) {
    padding: 50px 50px 80px;
    position:absolute;
    width: 30%;
    left: 7%;
    top: -8%;  
  }

  .label-project, .label-view-all {    
    font-size: 14px;    
    letter-spacing: 3px;
    margin-bottom: 0;
    padding-bottom: 5px;
  }

  .label-project {
    font-weight: 800;
    border-bottom: 3px solid black;
  }

  .label-view-all {
    float:right;
  }

  hr {
    margin-top: 5px;
    margin-bottom: 20px;

    @include breakpoint(large) {
      margin-top: 5px;
      margin-bottom: 70px;      
    }
  }

  h3 {
    font-weight: 400;
    font-size: 15px;   
    letter-spacing: 3px;         
  }

  h1 {
    font-weight: 800;
    font-size: 25px;    
    letter-spacing: 4px;
    @include breakpoint(medium) {
      font-size: 28px;
      letter-spacing: 5px;
    }
  }

  .label-excerpt {
    margin: 20px 0;
  }
  .view-project {
    font-size: 15px;
    font-weight: 800;

    
  }

  .project-nav {
    position: absolute;
    top: 0;
    bottom: auto;
    left: 0;
    width: 100%;
    text-align: center;

    @include breakpoint(large) {
      bottom: 0;
      top: auto;
    }

    p {
      font-size: 15px;  
      font-weight: 400;
      margin: 10px 0;
    }
    
  }
}

hr {
  border-bottom: 1px solid black;
}

.arrow-up {
  margin-left: 5px;
  width: 15px;
  display: inline-block!important;
}

.text-link {
  color: #000;
  font-weight: 800;
  font-size: 14px;  
  letter-spacing: 6px;  
}


.float-box {
  position:static;
  width: 100%;
  height: auto;
  padding: 30px;
  background: #E0C6B3;  

  @include breakpoint(large) {
    position:absolute;
    width: 300px;
    height: auto;
    padding: 30px;    
    left: -50px;
    bottom: -50px;    
  }
}



.fade-up {
  transform: translateY(15px);
  opacity: 0;
  transition: 0.9s all ease;
  &.show {
    transform: translateY(0);
    opacity: 1;
  }
}

.fade-down {
  transform: translateY(-15px);
  opacity: 0;
  transition: 0.9s all ease;
  &.show {
    transform: translateY(0);
    opacity: 1;
  }
}

.fade-left {
  transform: translateX(-15px);
    opacity: 0;
    transition: 0.9s all ease;
    &.show {
      transform: translateX(0);
      opacity: 1;
    }
}

.fade-right {
  transform: translateX(15px);
    opacity: 0;
    transition: 0.9s all ease;
    &.show {
      transform: translateX(0);
      opacity: 1;
    }
}

.fade-delay {
  transition-delay: 0.2s;
}

.fade-delay2 {
  transition-delay: 0.3s;
}

.fade-delay3{
  transition-delay: 0.4s;
}

.underline {
  border-bottom: 3px solid black;
}

.footer-pages {
    margin-left: 0;
  @include breakpoint(large) {
    margin-left: 20px;
  }
}


.walls {
  text-align: center;
  position: relative;

  .count {
    background: black;
    color: white;
    text-align: center;
    width: 18px;
    padding: 2px 0;
    position:absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-right: auto;
    margin-left: auto;
    font-size: 10px;
  }
  
  .img {
    position:relative;
    width: auto;    
    display: inline-block; 
    height: auto;
    width: 8%;
    transition: 0.3s all ease;    
    img {
      height: auto;
      @include breakpoint(large) {
        height: 100%;
      }
    }

    @include breakpoint(large) {
      height: 340px;
      width: auto;
    }
  }
  @for $i from 1 through 10 {
      $seconds: 0.1*$i;
      #wall#{$i} {        
        opacity: 0;
        animation-delay: #{$seconds}s;            
      }    
  }

  &.show {
    .img {
      animation: fade-in 1s ease;
      animation-fill-mode: forwards;      
    }
  }
}

@keyframes fade-in {
  0% {
      opacity: 0;         
  }
  100% {
      opacity: 1;
  }
}

.border-left {
  border-left: 1px solid #ececec;
  padding-top: 10px;
  padding-bottom: 50px;
  padding-left: 25px;
}


.faq { 
  border-bottom: 1px solid #000;  
  margin: 30px 0;  
  position: relative;
  overflow: hidden;
  transition: 0.3s all ease;
  padding-bottom: 30px;
  cursor: pointer;
}


.faq .fas {  
  color: black;
  font-size: 20px;
  position: absolute;
  opacity: 1;
  top: 5px;
  right: 10px;
  z-index: 1;
  transition: 0.4s all ease;
}

.faq.active .fas {
  transform: rotate(180deg);
}

.faq-title {
  margin: 0 35px 0 0;
}

.faq-text {
  display: none;
  margin: 10px 0 0;
}

.faq.active .faq-text {
  display: block;
}

.faq-toggle {
  background-color: transparent;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding: 0;
  position: absolute;
  top: 30px;
  right: 30px;
  height: 30px;
  width: 30px;
}

.faq-toggle:focus {
  outline: 0;
}

.faq-toggle .fa-times {
  display: none;
}

.faq.active .faq-toggle .fa-times {
  color: #fff;
  display: block;
}

.faq.active .faq-toggle .fa-chevron-down {
  display: none;
}

.faq.active .faq-toggle {
  background-color: #9fa4a8;
}


input, select, textarea {
  background-color: transparent!important;
  margin:0 0 15px;
  border: 0!important;
  box-shadow: none!important;
  outline: 0;
  border-style:solid;
  border-width:0;
  border-bottom: 1px solid black!important;
  font-size: 14px;  
  color:black!important;
  padding-left: 0!important;
}

textarea {
  min-height: auto;
}


.contact {
  hr {
    margin: 0;
  }
}

#emailSelect {
  border-bottom: 0!important;
}

#map {
  border-top: 5px solid black;
  width: 100%;
  height: 50vh;
}


.community-slide {
  width: 100%;
  // height: 30vh;

  // @include breakpoint(large) {
  //   height: 90vh;    
  // }

  .slide {
    position:relative;
    height: 30vh;
    background-color:white;

    @include breakpoint(large) {
      height: 90vh;    
    }


    background-size: cover;
    background-repeat: no-repeat;
    background-position:center center;
  }
  .proj {
    width: 55%;
    height: 100%;
    position:static;    
    cursor: pointer;    

    @include breakpoint(medium) {
      margin-top: 0;
      width: 30%;
      height: auto;
      position:absolute;
      left: 0;
      bottom: 0;
    }
  }

  .slide1 {
    background-image:url('/assets/images/nicola.jpg');
  }
  .slide2 {
    background-image:url('/assets/images/Lakewood-desk.jpg');
  }
  .slide3 {
    background-image:url('/assets/images/Templeton.jpg');
  }
  .slide4 {
    background-image:url('/assets/images/eternity.jpg');
  }  

  .past-proj {
    height: 80%;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position:absolute;
  }
}

.slide-nav {
  width: auto;
  position: fixed;  
  right: 0;
  background:white;
  z-index: 5;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);  
  transition: 0.4s all ease;
  display: none;
  padding: 15px 0;

  @include breakpoint(large) {
    display: block;
  }

  &.active {
    opacity: 1;
  }
  a {
    text-align: left;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // border-radius:50%;
    // width: 30px;
    // height: 30px;
    // margin: 0 auto;
    display: block;
    color: black;
    padding: 0 15px;
    background-color: transparent;
    font-size: 14px;    
    transition: 0.4s all ease;

    &:hover, &.active {
      background-color:black;
      color:white;
    }
  }

}

.sustainability {
  .wall-info {

    img {
      margin-bottom: 20px;
      width: 30%;

      @include breakpoint(large) {
        margin-bottom: 0;
        width: 100%;        
      }
    }

    .large-offset-1 {
      text-align: center;

      @include breakpoint(large) {
        text-align: left;        
      }
    }
  }
}


ol {
  margin: 0;
  padding: 0;
  
  li {
    margin-left: 2em;
  }
}



.what-we-do-icons {
  img {
    width: 15%;
    margin-bottom: 20px;
    @include breakpoint(large) {
      width: 25%;
    }
  }
}

.small-text-center {
  text-align: center;
  @include breakpoint(large) {
    text-align: left;
  }
}

.video-slide, .video-slide .slick-list, .video-slide .slick-track {
  height: 100%;   
}

.video-slide {
  background-color:black;
  .heading {
    padding: 30px 0;
  }

  .slide4 {
    background: url('/assets/images/hero-home.jpg') center center no-repeat;   
    background-size:cover; 
  }
}



.progressBarContainer {
  position: absolute;
  bottom: 10px;
  width:95%;
  left:0;
  right: 0;
  margin: 0 auto;

  @include breakpoint(large) {
    left:0;   
    width: 300px;
  }

}

.progressBarContainer div {
  display: block;
  width: 21%;
  padding: 0;
  cursor: pointer;
  margin-right: 5%;
  float: left;
  color: white;
}

.progressBarContainer div:last-child {
  margin-right: 0;
}

.progressBarContainer div span.progressBar {
  width: 100%;
  height: 4px;
  background-color: rgba(255, 255, 255, 0.4);
  display: block;
}

.progressBarContainer div span.progressBar .inProgress {
  background-color: rgba(255, 255, 255, 1);
  width: 0%;
  height: 4px;
}

.overlay {
  width: 100%;
  height: 100%;
  background-color:rgba(0,0,0,0.9);
  position:fixed;
  top: 0;
  left: 0;  
  z-index: 9999999;
  display: flex;
  align-items:center;
  justify-content: center;
  text-align: center;
  padding: 0 20px;
  display: none;
  &.active {
    display: flex;
  }

  h1 {
    color: white;
  }
  .close {
    color: white;
    position:absolute;
    top: 8px;
    right: 20px;
    font-size: 50px;
    line-height: 1;
    margin: 0;
    padding: 0;
    display: block;
  }
}